import * as React from "react";

const SVGRingCircle = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={56}
		height={56}
		viewBox="0 0 56 56"
		{...props}
	>
		<g
			id="Ellipse_14"
			data-name="Ellipse 14"
			fill="#e0f1f9"
			stroke="#dbeff2"
			strokeWidth={10}
		>
			<circle cx={28} cy={28} r={28} stroke="none" />
			<circle cx={28} cy={28} r={23} fill="none" />
		</g>
	</svg>
);

export default SVGRingCircle;
