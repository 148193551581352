import * as React from "react";

const SVGThinRing = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={740}
		height={740}
		viewBox="0 0 740 740"
		{...props}
	>
		<g
			id="Ellipse_18"
			data-name="Ellipse 18"
			fill="none"
			stroke="#dbeff2"
			strokeWidth={50}
		>
			<circle cx={370} cy={370} r={370} stroke="none" />
			<circle cx={370} cy={370} r={345} fill="none" />
		</g>
	</svg>
);

export default SVGThinRing;
