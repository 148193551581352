import React from "react";

import { GatsbyImage } from "gatsby-plugin-image";

import Col from "react-bootstrap/Col";
// this is used in the other component.
export default function ImageThumbnail({ isActive, onClick, image }) {
	const handleClick = () => {
		onClick(image);
	};

	return (
		<Col className="text-center">
			<GatsbyImage
				className="c-image"
				image={image}
				alt="mini"
				onClick={handleClick}
			/>
		</Col>
	);
}
