import React, { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import "react-multi-carousel/lib/styles.css";

// This is the component i was battling with i cant get the image to change with the state it just removes it. it might be a bug.
export function ImageGallery({ imageMain, image }) {
	const [activeImageThumbnail, setActiveImageThumbnail] = useState(imageMain);

	React.useEffect(() => {
		setActiveImageThumbnail(image || imageMain);
	}, [image, imageMain, setActiveImageThumbnail]);

	return (
		<GatsbyImage
			className="gallery-image"
			image={activeImageThumbnail}
			alt="main"
		/>
	);
}
